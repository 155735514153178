/* eslint-disable tailwindcss/no-custom-classname */
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useStore } from "@/context/store";
import { Meta } from "@/layout/Meta";
import {
  FirebaseProvider,
  app,
  useCurrentAuth,
} from "@/utils/FirebaseProvider";
import { Logo } from "../components/svg/Logo";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import Link from "next/link";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { getFunctions } from "firebase/functions";

// Must be outside of the render component

const Index = () => {
  const { register, handleSubmit, watch } = useForm<{
    email: string;
    password: string;
  }>();
  const emailWatch = watch("email");
  const { openModal } = useStore();
  const router = useRouter();
  const { auth } = FirebaseProvider;
  const [error, setError] = useState(false);
  const [user] = useCurrentAuth();
  const [executeCallable] = useHttpsCallable(
    getFunctions(app, "europe-west1"),
    "resetPassword"
  );

  useEffect(() => {
    if (user && user.tenantId) {
      router.replace("./dashboard");
    }
    if (user && !user.tenantId) {
      router.replace("./chooseCompany");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Meta title="ActProof" description="ActProof" />
      <div className="flex min-h-screen">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              {/* <img
                className="m-auto h-12 w-auto"
                src="/assets/images/actproof.png"
                alt="ActProof"
              /> */}
              <div className="flex justify-center ">
                <div className="flex h-[300px] w-[300px] justify-center ">
                  {Logo()}
                </div>
              </div>

              <h2 className="m-auto mt-6 text-center text-3xl font-extrabold text-gray-900">
                Logga in
              </h2>
            </div>

            <div className="mt-8">
              {/* <div>
                <div>
                  <p className="text-sm font-medium text-gray-700">
                    Sign in with
                  </p>

                  {}

                  <div className="mt-1 grid grid-cols-3 gap-3">
                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Facebook</span>
                        <svg
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Twitter</span>
                        <svg
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with GitHub</span>
                        <svg
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="relative mt-6">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div> */}
              {/* <div className="mt-6">
                {tenants.map((d, i) => (
                  <p key={i} onClick={() => setSelectedTenant(d)}>
                    {JSON.stringify(d)}
                  </p>
                ))}
              </div> */}

              <div className="mt-6">
                <form
                  onSubmit={handleSubmit(async ({ email, password }) => {
                    setError(false);
                    try {
                      const res = await signInWithEmailAndPassword(
                        auth,
                        email,
                        password
                      );
                      if (res.user) {
                        router.push("./chooseCompany");
                      }
                    } catch (err) {
                      setError(true);
                      // eslint-disable-next-line no-console
                      console.log(err);
                    }
                  })}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-mail
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        {...register("email")}
                        type="email"
                        // defaultValue={"adminap2@grr.la"}
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-hred-100 focus:outline-none focus:ring-hred-100 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Lösenord
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        {...register("password")}
                        type="password"
                        // defaultValue={"!Password9"}
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-hred-100 focus:outline-none focus:ring-hred-100 sm:text-sm"
                      />
                    </div>
                    {error && (
                      <p className="block pt-3 text-sm font-medium text-red-500">
                        Fel email / lösenord
                      </p>
                    )}
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-hred-200 focus:ring-hred-100"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Kom ihåg mig
                      </label>
                    </div>

                    <div className="text-sm">
                      <a
                        href="#"
                        onClick={() => {
                          if (emailWatch) {
                            openModal({
                              message: emailWatch,
                              title: "Återställ lösenord",
                              type: "alert",
                              onAcceptModal: async () => {
                                const res = await executeCallable({
                                  email: emailWatch,
                                });

                                if (res?.data) {
                                  toast.success("Email skickat");
                                } else {
                                  toast.error("Error inget konto");
                                }
                              },
                            });
                          } else {
                            toast.error("Ingen email angiven");
                          }
                        }}
                        className="font-medium text-hred-200 hover:text-hred-100"
                      >
                        Glömt lösenord?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-hred-200 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-horange-100 focus:outline-none focus:ring-2 focus:ring-hred-100 focus:ring-offset-2"
                    >
                      Logga in
                    </button>
                    <Link href={"./register"} passHref>
                      <a
                        type="button"
                        className="mt-5 flex w-full justify-center rounded-md border border-transparent bg-hred-200 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-horange-100 focus:outline-none focus:ring-2 focus:ring-hred-100 focus:ring-offset-2"
                      >
                        Registrera
                      </a>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={`https://source.unsplash.com/random/1024x1024/?construction`}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Index;
