import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  Timestamp,
} from "firebase/firestore";
import _ from "lodash";
import { convertToDate } from "./convertToDate";

export function checkObject(data: DocumentData): DocumentData {
  if (data instanceof Timestamp) {
    return data.toDate();
  }
  if (_.isPlainObject(data)) {
    return _.mapValues(data, checkObject);
  }
  if (_.isArray(data)) {
    return data.map(checkObject);
  }

  if (data instanceof DocumentReference) {
    return {};
  }

  // @ts-ignore
  if (data === undefined || data === "undefined") {
    // @ts-ignore
    return null;
  }

  return data;
}

export const timestampConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore: (data) => checkObject(convertToDate(data)),
  fromFirestore: (data) => checkObject(data.data()),
};
