import { useCurrentProfile } from "./useCurrentProfile";
import { IProject, IProjectUser, IUser, Role } from "../../../common";
import { useSelectedProject } from "./useSelectedProject";
import { useCurrentAuth } from "./FirebaseProvider";

export const findRole = ({
  user,
  project,
}: {
  user?: IUser | IProjectUser;
  project: IProject | undefined;
}): Role => {
  if(user && project) {
    if (project.projectManagers?.find((manager) => manager.email === user.email)) {
      return { level: 5, title: "projectManager" };
    }
    if (project.siteManagers?.find((manager) => manager.email === user.email)) {
      return { level: 4, title: "siteManager" };
    }
    if (project.staffManagers?.find((manager) => manager.email === user.email)) {
      return { level: 3, title: "supervisor" };
    }
    if (project.foremans?.find((manager) => manager.email === user.email)) {
      return { level: 2, title: "foreman" };
    }
    if (project.subcontractors?.find((manager) => manager.email === user.email)) {
      return { level: 1, title: "subcontractor" };
    }
  }
    return { level: 0, title: "worker" };
};

export const useFeatureToggle = () => {

  const [loggedInUser, loading1] = useCurrentProfile();
  const [selectedProject, loading2] = useSelectedProject() ;
  // eslint-disable-next-line @typescript-eslint/naming-convention, unused-imports/no-unused-vars
  const [_, loading3] = useCurrentAuth()

  return {
    accessRight: loggedInUser?.accessRight || 1,
    role: findRole({user: loggedInUser, project: selectedProject}),
    internalExternal: loggedInUser?.employmentType || "external",
    isAgent: !!selectedProject?.agents?.find(
      (x: any) => x.email === loggedInUser?.email
    ),
    isSubcontractor: !!selectedProject?.subcontractors?.find(
      (x: any) => x.email === loggedInUser?.email
    ),
    loading: loading1 || loading2 || loading3
  };
};
