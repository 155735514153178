 export const technologyArea = [
    "Akustik",
    "Arkitektur",
    "Brand",
    "Bygg",
    "Data/Tele",
    "EL",
    "El-Mark",
    "Energi",
    "Fastighetsskötare",
    "Fukt",
    "Gas",
    "Geoteknik",
    "Hiss",
    "Hydraulik",
    "Hyresgäst",
    "Inredning Konstruktion",
    "Isolering",
    "Konstruktion-Mark",
    "Kyla",
    "Landskap",
    "Mark",
    "Mätteknik",
    "Miljö",
    "Plåt",
    "Prefab",
    "Rör",
    "Säkerhet",
    "Sprinkler",
    "Stomme",
    "Storkök",
    "Styr/Regler/Övervakning",
    "Tillgänglighet",
    "Trafikdesign",
    "VA-Mark",
    "VA-Process",
    "Värme sanitet",
    "Värme Ventilation Sanitet",
    "Ventilation"
  ];
