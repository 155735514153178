import { doc, FirestoreDataConverter } from "firebase/firestore";
import { useRouter } from "next/router";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { IProject } from "../../../common";
import { db, useCurrentAuth } from "./FirebaseProvider";
import { checkObject } from "./timestampConverter";

const converter: FirestoreDataConverter<IProject> = {
  toFirestore: (data) => JSON.parse(JSON.stringify(checkObject(data))),
  fromFirestore: (data) => checkObject(data.data()) as IProject,
};

export const useSelectedProject = () => {
  const [user] = useCurrentAuth();
  const { query } = useRouter();
  const project = useDocumentData(
    doc(
      db,
      `tenants/${user?.tenantId || "no-id"}/projects`,
      `${query?.projectId || "no-id"}`
    ).withConverter(converter)
  );

  return project;
};

export const useSelectedProjectWithSubscription = () => {
  const [user] = useCurrentAuth();
  const { query } = useRouter();
  const project = useDocumentData(
    doc(
      db,
      `tenants/${user?.tenantId || "no-id"}/projects`,
      `${query?.projectId || "no-id"}`
    ).withConverter(converter)
  );

  return project;
};
