import _ from "lodash";
import moment from "moment";

export const convertToDate: any = (obj: any) => {
  return _.mapValues(obj, (value) => {
    if (_.isArray(value)) {
      // return _.map(value, (item) => {
      //   return convertToDate(item);
      // });
    }
    if (_.isPlainObject(value)) {
      return convertToDate(value);
    }

    const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/;
    const isValidISODate = isoDateRegex.test(value);
    if (_.isString(value) && isValidISODate) {
      return moment(value).toDate();
    }
    // if (
    //   _.isNumber(value) &&
    //   moment(value).isValid() &&
    //   moment(value).isBetween("1990-01-01", "2040-12-31")
    // ) {
    //   return moment(value).toDate();
    // }

    return value;
  });
};