import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { ArrowsExpandIcon } from "@heroicons/react/outline";
import * as HeroIcons from "@heroicons/react/outline";
import { useSelectedProject } from "../../utils/useSelectedProject";
import { UseDataSearchInput } from "../SearchInput/SearchInput";
import Image from "next/image";
import defaultImage from "../../assets/default.png";
import { useFeatureToggle } from "@/utils/useFeatureToggle";
import { useData } from "@/utils/useData";

export const ProjectSideBar = (props: { children: React.ReactChildren }) => {
  const { query, asPath, push } = useRouter();
  const path = `/data/projects/${query?.projectId}`;
  const [selectedProject] = useSelectedProject();
  const [isMenuOpen, setIsMenuOpen] = useState(
    localStorage.getItem("sidebarOpen") === "false"
  );
  const {
    internalExternal,
    accessRight,
    loading: loading2,
  } = useFeatureToggle();
  const { loading } = useData();

  const style = (val: string, override?: boolean) =>
    clsx(
      "col-span-1 w-9 rounded-lg bg-gray-300 p-2 hover:cursor-pointer",
      {
        "bg-indigo-400 stroke-white": override || val === asPath,
      },
      {
        "bg-indigo-400 stroke-white": val
          .replace(path, "")
          .includes(asPath.split("/").slice(0, -1).join("/")),
      },
      {
        "bg-indigo-400 stroke-white": val
          .replace(path, "")
          .includes(asPath.split("/").slice(-2, -1).join("/")),
      },
      {
        "bg-indigo-400 stroke-white": val
          .replace(path, "")
          .includes(asPath.split("/").slice(-3, -2).join("/")),
      },
      {
        "bg-indigo-400 stroke-white": val === asPath.replace(/\?.*/, ""),
      }
    );

  const HeroIcon = (val: React.ComponentProps<"svg">, displayName: string) => {
    // @ts-ignore
    const IconComponent = HeroIcons[val.name];
    const [showTooltip, setShowTooltip] = useState(false);

    if (!IconComponent) {
      return null;
    }

    return (
      <>
        <IconComponent
          {...val}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        />
        {showTooltip && !isMenuOpen && (
          <div className="absolute left-10 rounded bg-gray-800 p-2 text-xs text-white opacity-90">
            {displayName}
          </div>
        )}
      </>
    );
  };

  const Item: React.FC<{
    displayName: string;
    link: string;
    iconName: string;
    disabled?: boolean;
    className?: string;
    override?: boolean;
  }> = ({ displayName, link, disabled, className, iconName, override }) => (
    <div
      className={clsx("py-2 md:grid md:grid-cols-4 md:gap-1", className, {
        "md:grid-cols-2": !isMenuOpen,
      })}
    >
      {HeroIcon(
        {
          name: iconName,
          className: override ? style("", override) : style(link),
          onClick: () => push(link || "#"),
        },
        displayName
      )}
      <Link href={disabled ? "#" : `${link}`} passHref>
        <p
          className={clsx(
            "col-span-3 p-2 text-sm font-semibold capitalize hover:cursor-pointer hover:text-gray-600 print:hidden",
            {
              "text-indigo-400":
                link === asPath || link === asPath.replace(/\?.*/, ""),
            },
            {
              "text-gray-400": disabled,
            },
            { hidden: !isMenuOpen }
          )}
        >
          {displayName}
        </p>
      </Link>
    </div>
  );

  useEffect(() => {
    localStorage.setItem("sidebarOpen", isMenuOpen.toString());
  }, [isMenuOpen]);

  useEffect(() => {
    const savedSidebarOpen = localStorage.getItem("sidebarOpen");
    if (savedSidebarOpen) {
      setIsMenuOpen(savedSidebarOpen === "false");
    }
  }, []);

  if (loading || loading2) return null;

  return (
    <div className="-m-10 flex flex-col rounded-lg p-5">
      <div className="grid grid-cols-8 gap-4 px-11 print:hidden">
        <div
          className={clsx(
            "col-span-1  col-end-7 mb-6",
            {
              "col-start-2": isMenuOpen,
            },
            {
              "col-start-1 pl-3": !isMenuOpen,
            }
          )}
        >
          <UseDataSearchInput />
        </div>
      </div>
      <div className="md:grid md:grid-cols-8 md:gap-6">
        <div className="col-span-1 print:hidden">
          <div
            className={clsx("fixed z-10", {
              "w-0": !isMenuOpen,
            })}
          >
            <div
              // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
              className="relative -top-[125px] flex flex-col"
            >
              <div className="h-20 w-20 flex-shrink-0">
                <Image
                  className="rounded-full object-cover"
                  width={isMenuOpen ? 300 : 40}
                  height={isMenuOpen ? 300 : 40}
                  src={
                    (selectedProject?.images && selectedProject.images[0]) ||
                    defaultImage
                  }
                  alt="img"
                />
              </div>
              <div className="pt-5">
                <div
                  className={clsx(
                    "font-medium text-gray-900 hover:cursor-pointer",
                    {
                      hidden: !isMenuOpen,
                    }
                  )}
                >
                  {selectedProject?.name}
                </div>
              </div>
            </div>
            <div
              className={clsx("relative -top-28", {
                "-top-24": !isMenuOpen,
              })}
            >
              {isMenuOpen ? (
                <div
                  className="col-span-1 mb-2 w-9 rounded-lg bg-gray-300 p-2 hover:cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    localStorage.setItem("sidebarOpen", "false");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                    />
                  </svg>
                </div>
              ) : (
                <ArrowsExpandIcon
                  className="col-span-1 mb-2 w-9 rounded-lg bg-gray-300 p-2 hover:cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(true);
                    localStorage.setItem("sidebarOpen", "true");
                  }}
                />
              )}
              <Item
                displayName="Översikt"
                link={`${path}/overview`}
                iconName="ViewGridIcon"
              />
              <Item
                displayName="Projektinfo"
                link={`${path}`}
                className={clsx("", {
                  hidden: internalExternal === "external" || accessRight < 2,
                })}
                iconName="InformationCircleIcon"
              />
              <Item
                displayName="Arbetsmiljö"
                link={`${path}/safetyRounds`}
                iconName="ViewBoardsIcon"
              />
              <Item
                displayName="Personer"
                link={`${path}/project_users`}
                iconName="UserGroupIcon"
              />
              <Item
                displayName="Kvalité"
                link={`${path}/avsyn`}
                iconName="CogIcon"
                override={[
                  "avsyn",
                  "avvikelserapport",
                  "egenkontroll",
                  "forbesiktning",
                  "faltrapport",
                ].some((e) => asPath.indexOf(e) > -1)}
              />
              <Item
                displayName="Inlägg"
                link=""
                disabled
                iconName="NewspaperIcon"
              />
              <Item
                displayName="Planering"
                link=""
                disabled
                iconName="LightBulbIcon"
              />
              <Item displayName="Inköp" link="" disabled iconName="CubeIcon" />
              <Item
                displayName="FS"
                link={`${path}/fs`}
                iconName="ChatAlt2Icon"
              />
              <Item
                displayName="ÄTA"
                link={`${path}/ata`}
                iconName="RefreshIcon"
              />
              <Item
                disabled={
                  !selectedProject?.hasNotebook ||
                  selectedProject?.hasNotebook === "no"
                }
                displayName="Dagbok"
                link={
                  !selectedProject?.hasNotebook ||
                  selectedProject?.hasNotebook === "no"
                    ? "#"
                    : `${path}/notebook`
                }
                iconName="BookOpenIcon"
              />
              <Item
                displayName="Tidsrapportering"
                link=""
                disabled
                iconName="CalendarIcon"
              />
              <Item
                displayName="Dokument"
                link={`${path}/documents`}
                iconName="DocumentTextIcon"
              />
            </div>
          </div>
        </div>
        <div
          className={clsx("col-span-7 print:col-span-8", {
            "z-0 col-span-8 -mt-6 pl-6": !isMenuOpen,
          })}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};
