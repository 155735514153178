import { DocumentReference } from "firebase/firestore";
import { StorageReference } from "firebase/storage";
import create from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";

interface Store {
  token: string;
  selectedModal?: "alert" | "info";
  message: string;
  title: string;
  openModal: (options: {
    type: "alert" | "info";
    message: string;
    title: string;
    onAcceptModal?: () => void;
    onCloseModal?: () => void;
  }) => void;

  openAlertModal: (cb: () => void, title?: string, message?: string) => void;
  openInformModal: (cb: () => void, title?: string, message?: string) => void;
  onAcceptModal?: () => void;
  onCloseModal?: () => void;
  setToken: (token: string) => void;
  clearModal: () => void;
}

export const useStore = create<Store>(
  persist(
    (set, get) => ({
      token: "",
      setToken: (token: string) => {
        localStorage.setItem("token", token);
        return set(() => ({ token }));
      },
      message: "",
      title: "",
      openModal: (v: any) => {
        set({
          selectedModal: v.type,
          message: v.message,
          title: v.title,
          onAcceptModal: v.onAcceptModal,
          onCloseModal: v.onCloseModal,
        });
      },

      openAlertModal: (v, title, message) => {
        get().openModal({
          type: "alert",
          onAcceptModal: v,
          title: title || "Är du säker?",
          message: message || "Dina ändringar är permanenta!",
        });
      },

      openInformModal: (v, title, message) => {
        get().openModal({
          type: "info",
          onAcceptModal: v,
          title: title || "Är du säker?",
          message: message || "",
        });
      },

      clearModal: () => {
        set({
          selectedModal: undefined,
          message: "",
          title: "",
          onAcceptModal: undefined,
          onCloseModal: undefined,
        });
      },
    }),
    {
      name: "store",
    }
  )
);

export const useSessionStore = create(
  subscribeWithSelector(() => ({
    searchKey: "",
    orderBy: "",
    orderDirection: "asc" as "asc" | "desc",
    referenceDelete: [] as DocumentReference<any>[],
    storageDelete: [] as StorageReference[],
  }))
);
