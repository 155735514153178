import { Fragment, InputHTMLAttributes } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { SaveButton } from "../Form/SaveButton";

export type DefaultDiv = InputHTMLAttributes<HTMLDivElement>;

interface IProps extends DefaultDiv {
  open: boolean;
  saveButtonTitleText?: string;
  hideButton?: boolean;
  onClose: () => void;
  onSave?: () => void;
  showX?: boolean;
}

export const Modal: React.FC<IProps> = (props) => {
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  className={clsx(
                    "relative h-full w-1/3 rounded-lg bg-white",
                    props.className
                  )}
                >
                  {props.showX && (
                    <p
                      className="absolute top-2 right-2 hover:cursor-pointer"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 25L25 13M13 13L25 25"
                          stroke="#374151"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="37"
                          height="37"
                          rx="5.5"
                          stroke="#D1D5DB"
                        />
                      </svg>
                    </p>
                  )}
                  {props.children}

                  {!props.hideButton && (
                    <div className="p-4">
                      <SaveButton
                        className=" inline-flex  justify-center  px-4 py-2"
                        onClick={() => {
                          if (props.onSave) {
                            props.onSave();
                          }
                          props.onClose();
                        }}
                        title={props.saveButtonTitleText}
                      ></SaveButton>
                    </div>
                  )}
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
