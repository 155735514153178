import { FirebaseOptions, initializeApp } from "firebase/app";
import { doc, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { timestampConverter } from "./timestampConverter";
import { useEffect, useState } from "react";
import _ from "lodash";

export const FirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDLLVO4vZc4Pd2Y2-4kE3XZ32a5mCTJOrw",
  authDomain: "actproff-app.firebaseapp.com",
  projectId: "actproff-app",
  storageBucket: "actproff-app.appspot.com",
  messagingSenderId: "969758921861",
  appId: "1:969758921861:web:42b384ce6cf7ed39be85ec",
  measurementId: "G-TZDCWCS46X",
};

export const app = initializeApp(FirebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

export const createDocumentRef = (path: string) =>
  doc(db, path).withConverter(timestampConverter);

export const FirebaseProvider = {
  app,
  storage,
  db,
  auth,
};

export const useCurrentAuth = () => {
  // eslint-disable-next-line prefer-const
  // let [profile, loading, error] = useAuthState(auth);

  const [loading2, setLoading2] = useState(false);
  const [claims, setClaims] = useState<string>("");
  const [p, setProfile] = useState<User | null>(null);
  let profile = p;
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setProfile(user);
      } else {
        // User is signed out
        setProfile(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function exec() {
      if (profile && !profile.email && !claims) {
        setLoading2(true);
        const { claims: newClaims } = await profile.getIdTokenResult();
        setClaims(newClaims.email);
        setProfile({ ...profile });
        setLoading2(false);
      }
    }
    exec();
  }, [claims, profile, loading2]);

  if (claims && profile) {
    profile = _.set({ ...profile }, "email", claims || profile?.email || "");
  }

  return [profile, loading2] as [User | null | undefined, boolean];
};

/**
 * Belgium tier 1 region for functions
 *
 * https://firebase.google.com/docs/functions/locations
 */
export const FirebaseRegion = "europe-west1";
