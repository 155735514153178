import { useSessionStore } from "@/context/store";
import { db, useCurrentAuth } from "@/utils/FirebaseProvider";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { useRouter } from "next/router";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { timestampConverter } from "./timestampConverter";
import { useSearch } from "./useSearch";

export type WithRef<T> = T & {
  ref: DocumentReference<DocumentData>;
  id: string | undefined;
};

const convertUndefinedToNull: any = (obj: any) => {
  return _.mapValues(obj, (value: any) => {
    if (_.isPlainObject(value)) {
      return convertUndefinedToNull(value);
    }
    return value === undefined ? null : value;
  });
};
const falseValueConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore: (data) => convertUndefinedToNull(data),
  fromFirestore: (data) => data.data(),
};

function getQueryParams() {
  const queryString = window.location.search.substring(1);

  const queryParams = queryString
    ? queryString.split("&").reduce((acc, curr) => {
        const key = curr.split("=")[0];
        const value = curr.split("=")[1];
        // @ts-ignore
        acc[key] = value;
        return acc;
      }, {})
    : {};

  return queryParams;
}

export function stripQueryStringAndHashFromPath(url: string): string {
  // @ts-ignore
  return url.split("?")[0].split("#")[0];
}

interface IOverride {
  pathOveride?: string;
}

export function useData<T>(props?: IOverride) {
  const router = useRouter();
  const [user] = useCurrentAuth();

  const searchKey = useSessionStore((c) => c.searchKey);
  const orderBy = useSessionStore((c) => c.orderBy);
  const orderDirection = useSessionStore((c) => c.orderDirection);

  const { search } = useSearch();

  // PATH CHECK START
  const path =
    stripQueryStringAndHashFromPath(props?.pathOveride || router.asPath).split(
      "/data/"
    )[1] || "";

  const split = _.split(path, "/");

  const calculate = path ? split.length % 2 : 0;
  const docPath = calculate === 0 ? path : "";
  const collPath = calculate === 1 ? path : "collsnotexist";

  const doccumentPath = user?.tenantId
    ? `tenants/${user?.tenantId || "user-id"}/${docPath}`
    : "noid/noid";

  const collectionPath =
    user?.tenantId && collPath
      ? `tenants/${user?.tenantId || "no-id"}/${collPath || "no-id"}/`
      : "noid/noid/noid";

  // PATH CHECK END

  // FILTER COLLECTION QUERY START
  const collectionReturn = collection(db, collectionPath);
  const params = getQueryParams() as any;
  const queries = Object.keys(params);
  const wheres = queries.map((c) => where(c, "==", params[c]));

  const queryWithOrWithoutFilter =
    wheres.length > 0
      ? query(collectionReturn, ...wheres)
      : query(collectionReturn);
  // FILTER COLLECTION QUERY END

  // DATAFETCH START
  const colReturn = useCollectionData(
    queryWithOrWithoutFilter
      .withConverter(falseValueConverter)
      .withConverter(timestampConverter)
  );
  const docReturn = useDocumentData(
    doc(db, doccumentPath)
      .withConverter(falseValueConverter)
      .withConverter(timestampConverter)
  );

  let [value1] = docReturn;
  const [, , , s] = docReturn;
  let [value2] = colReturn;
  const [, , , s2] = colReturn;
  // DATAFETCH END

  // APPEND REF AND ID ****** OBS REF IS DANGEROUS AND NEEDS TO BE EMITTED AT PLACES WHEN MEMORYOVERFLOW ******
  value2 = value2?.map((v, i) => {
    return {
      ...v,
      ref: s2?.docs[i]?.ref,
      id: s2?.docs[i]?.id,
    };
  });

  const arrayResult = _.sortBy(search(value2 || []), ["title", "name", "id"]);
  const lodashValue2 = _(arrayResult).orderBy(orderBy, orderDirection).value();

  value1 = value1 ? { ...value1, id: s?.id, ref: s?.ref } : undefined;

  const getParentDoc = async () => {
    // Assume you have a Firestore instance initialized as "db"
    let parentData;
    // Get a reference to a specific document
    const myDocRef = docReturn[3];

    // Get a reference to the parent document
    if (myDocRef) {
      const parentDocRef = myDocRef.ref.parent.parent;
      if (parentDocRef) {
        // Get the data for the parent document
        const parentDocSnapshot = await getDoc(parentDocRef);
        if (parentDocSnapshot.exists()) {
          parentData = parentDocSnapshot.data();
          // Do something with the parent data
        }
      }
    }
    return parentData;
  };

  return {
    data: (calculate === 0 ? value1 : lodashValue2) as T | undefined,
    doccument: docReturn,
    collection: colReturn,
    currentRef: calculate === 0 ? s : s2,
    searchKey,
    loading: docReturn[1] || colReturn[1],
    getParentDoc,
  };
}
