import { ref, getDownloadURL } from "firebase/storage";
import { useUploadFile } from "react-firebase-hooks/storage";
import React, { InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";
import { FirebaseProvider, useCurrentAuth } from "@/utils/FirebaseProvider";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import clsx from "clsx";
import Image from "next/image";
import { Triangle } from "react-loader-spinner";
import { FaFilePdf } from "react-icons/fa";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  required?: boolean;
  folder?: string;
  customPath?: string;
  isArray: boolean;
}

export const UploadFile: React.FunctionComponent<IProps> = (props) => {
  const { formState, register, getValues, setValue, clearErrors, watch } =
    useFormContext();
  const error = formState.errors[props.name];
  const [user] = useCurrentAuth();
  const uploadPath =
    user && props.folder
      ? `Tenants/${user?.tenantId}/${_.upperFirst(props.folder)}/${_.upperFirst(
          getValues().name ||
            getValues().displayName ||
            getValues().title ||
            getValues().id ||
            "name"
        )}/Images`
      : props.customPath
      ? props.customPath
      : `Tenants/${user?.tenantId}/Data/Images`;
  const [uploadFile, load] = useUploadFile();

  const images: string[] = watch(props.name);
  const image: string = watch(props.name);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    maxSize: 30000000,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map(async (file) => {
        const uploadRef = ref(
          FirebaseProvider.storage,
          `${uploadPath}/${crypto.randomUUID() + file.name}`
        );
        await uploadFile(uploadRef, file);
        await getDownloadURL(uploadRef)
          .then((res) => {
            clearErrors();
            if (props.isArray) {
              setValue(props.name, images ? [...images, res] : [res]);
            } else {
              setValue(props.name, res);
            }

            //  reset(getValues(), { keepErrors: true });
          })
          .catch(() => {});
      });
    },
    onFileDialogOpen: () => {
      clearErrors();
    },
  });

  return (
    <>
      {image && !props.isArray ? (
        <div className="h-40 w-40">
          <Image
            src={image}
            alt="img"
            width={140}
            height={140}
            className="rounded-lg object-contain"
          />
          <div className="flex items-end ">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => {
                setValue(props.name, "");
              }}
            >
              Ändra
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="relative col-span-6 mt-1 flex">
            {images &&
              images.length > 0 &&
              images?.map((x, idx) => (
                <div
                  key={idx}
                  className="flex flex-col p-1 hover:cursor-pointer"
                >
                  {x.includes(".pdf") ? (
                    <FaFilePdf
                      size={50}
                      className={"m-4 text-red-900"}
                      onClick={() => window.open(x)}
                    ></FaFilePdf>
                  ) : (
                    <Image
                      src={x}
                      alt="img"
                      width={140}
                      height={140}
                      className="rounded-lg object-contain"
                      onClick={() => window.open(x)}
                    />
                  )}
                  {!props.disabled && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setValue(
                          props.name,
                          images.filter((e) => e !== x)
                        );
                      }}
                    >
                      Ändra
                    </button>
                  )}
                </div>
              ))}
          </div>

          <div className="col-span-6 sm:col-span-6" {...getRootProps()}>
            <div
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={clsx(
                "mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6",
                {
                  "border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500":
                    !error,
                },
                {
                  "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
                    error,
                },
                {
                  "hidden w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm":
                    props.disabled,
                },
                props.className
              )}
            >
              <Triangle
                height="72"
                width="72"
                color="blue"
                ariaLabel="three-dots-loading"
                visible={load}
              />
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="inputError relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>{props.title || "Ladda upp fil"}</span>
                    <input
                      {...register(props.name, { required: !!props.required })}
                      {...props}
                      {...getInputProps()}
                      id="file-upload"
                      type=""
                      className="inputError sr-only"
                    />
                  </label>
                  <p className="pl-1">eller drag & drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF upp till 10MB
                </p>
                {fileRejections?.map((x, idx) => (
                  <div key={idx}>
                    {x.errors.map((e) => (
                      <p key={e.code} className="text-red-500">
                        {e.message.replace("/*", "")}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
