import { useSessionStore } from "@/context/store";
import { useEffect } from "react";

function createSearchKey(o: any) {
  let value = "";
  const keys = Object.keys(o);
  keys.forEach((k) => {
    if (typeof o[k] === "string") {
      value = value.concat(o[k]);
    }
  });

  return value;
}

export const useSearch = () => {
  const searchKey = useSessionStore((c) => c.searchKey);
  const setSearchKey = (s: string) =>
    useSessionStore.setState({ searchKey: s });

  useEffect(() => {
    setSearchKey("");
  }, []);

  function search(arr: any[]) {
    if (searchKey && searchKey.length > 0) {
      const withSearchKey = arr?.map((c) => ({
        ...c,
        searchKey: createSearchKey(c),
      }));

      const result = withSearchKey?.filter(
        (c: any) =>
          c.name?.toLowerCase().includes(searchKey.toLowerCase()) ||
          c.email?.toLowerCase().includes(searchKey.toLowerCase()) ||
          c.project_location?.formatted_address
            ?.toLowerCase()
            .includes(searchKey.toLowerCase()) ||
          c.visting_address?.formatted_address
            ?.toLowerCase()
            .includes(searchKey.toLowerCase()) ||
          c.customer?.name?.toLowerCase().includes(searchKey.toLowerCase()) ||
          c.projectManagers?.find((m: any) =>
            m.name?.toLowerCase().includes(searchKey.toLowerCase())
          ) ||
          c.searchKey.toLowerCase().includes(searchKey.toLocaleLowerCase())
      );

      if (result?.length) {
        return result;
      }
      return [];
    }
    return arr;
  }

  return {
    search,
  };
};
