import React, { ButtonHTMLAttributes } from "react";
import clsx from "clsx";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const SaveButton: React.FC<IProps> = (props) => {
  return (
    <div className="flex justify-end">
      <button
        {...props}
        className={clsx(
          props.className,
          "ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
          {
            "hidden cursor-not-allowed rounded bg-gray-500 py-2 px-4 font-bold text-white opacity-50":
              props.disabled,
          }
        )}
      >
        {props.title || "Spara"}
      </button>
    </div>
  );
};
