 export const professions = [
    "Affärschef",
    "Arbetsledare",
    "Arkitekt",
    "Besiktningsman",
    "Beställare",
    "Fastighetstekniker",
    "Ingenjör",
    "Inköpare",
    "KA",
    "Kalkylator",
    "Konstruktör",
    "Ledande montör",
    "Maskinförare",
    "Montör",
    "Platschef",
    "Projektchef",
    "Teknik chef",
    "Tekniker",
    "Yrkesarbetare"
  ];



