import { doc, FirestoreDataConverter } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { IUser } from "../../../common";
import { db, useCurrentAuth } from "./FirebaseProvider";
import { timestampConverter } from "./timestampConverter";

const userConverter: FirestoreDataConverter<IUser> = {
  toFirestore: (data) => data,
  fromFirestore: (data) => data.data() as IUser,
};

export const useCurrentProfile = () => {
  const [user] = useCurrentAuth();
  return useDocumentDataOnce(
    doc(
      db,
      `tenants/${user?.tenantId || "no-id"}/profiles`,
      `${user?.email || "no-id"}`
    )
      .withConverter(timestampConverter)
      .withConverter(userConverter)
  );
};
