/* eslint-disable tailwindcss/no-custom-classname */
import React from "react";
import { Sidebar } from "flowbite-react";
import {
  CloudIcon,
  HomeIcon,
  CogIcon,
  OfficeBuildingIcon,
  IdentificationIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { useRouter } from "next/router";
import clsx from "clsx";
import { useFeatureToggle } from "@/utils/useFeatureToggle";
import { useCurrentAuth } from "@/utils/FirebaseProvider";

export function SidebarComponent() {
  const { asPath } = useRouter();

  const [currentUser] = useCurrentAuth();
  const { accessRight, internalExternal } = useFeatureToggle();

  const Item: React.FC<{ displayName: string; path: string }> = ({
    displayName,
    path,
  }) => (
    <p
      className={clsx(
        "capitalize text-gray-300",
        {
          "text-indigo-400": asPath.replace(/(.*\/)*/, "") === path,
        },
        {
          "text-indigo-400": asPath.replace("/data", "").includes(path),
        }
      )}
    >
      {displayName}
    </p>
  );

  const style = (path: string, disabled?: boolean) =>
    clsx("w-6 stroke-gray-400", {
      "fill-indigo-500 stroke-indigo-300": asPath
        .replace("/data", "")
        .includes(path),
      invisible: disabled,
    });

  return (
    <div className="flex">
      <Sidebar
        className={clsx("overflow-hidden bg-gray-800", {
          hidden: !currentUser?.tenantId,
        })}
        aria-label="Sidebar with multi-level dropdown"
      >
        <div>
          <Sidebar.Items className="hello bg-menublue-100">
            <Sidebar.ItemGroup>
              {
                <div
                  className={clsx("m-0 p-0", {
                    hidden: true,
                  })}
                >
                  <Sidebar.Item
                    href="/dashboard"
                    icon={() => <HomeIcon className={style("dashboard")} />}
                  >
                    <Item displayName="dashboard" path="dashboard" />
                  </Sidebar.Item>
                </div>
              }

              <Sidebar.Item
                href="/data/projects"
                icon={() => (
                  <OfficeBuildingIcon className={style("projects")} />
                )}
              >
                <Item displayName="projekt" path="projects" />
              </Sidebar.Item>

              <div
                className={clsx("m-0 p-0", {
                  hidden: accessRight < 2,
                })}
              >
                <Sidebar.Item
                  href="/data/companies"
                  icon={() => (
                    <IdentificationIcon className={style("companies")} />
                  )}
                >
                  <Item displayName="kunder" path="companies" />
                </Sidebar.Item>
              </div>

              <div
                className={clsx("m-0 p-0", {
                  hidden: true,
                })}
              >
                <Sidebar.Item
                  href="/services"
                  icon={() => <CloudIcon className={style("services")} />}
                >
                  <Item displayName="services" path="services" />
                </Sidebar.Item>
              </div>

              <div
                className={clsx("m-0 p-0", {
                  hidden: internalExternal === "external",
                })}
              >
                <Sidebar.Item
                  href="/data/profiles"
                  icon={() => (
                    <UsersIcon
                      className={style(
                        asPath === "/public_users" ? "public_users" : "profiles"
                      )}
                    />
                  )}
                >
                  <Item
                    displayName="Användare"
                    path={
                      asPath === "/public_users" ? "public_users" : "profiles"
                    }
                  />
                </Sidebar.Item>
              </div>
              {/* <div
                className={clsx("m-0 p-0", {
                  hidden: accessRight < 4,
                })}
              >
                <Sidebar.Item
                  href="/fileImport"
                  icon={() => <ArchiveIcon className={style("fileImport")} />}
                >
                  <Item displayName="fileimport" path="fileImport" />
                </Sidebar.Item>
              </div> */}
              <div
                className={clsx("m-0 p-0", {
                  hidden: internalExternal === "external" || accessRight < 2,
                })}
              >
                <Sidebar.Collapse
                  label="Inställningar"
                  className="hello2 text-gray-300"
                  icon={CogIcon}
                  open={
                    asPath === "/data" ||
                    asPath.includes("/data/checklists") ||
                    asPath.includes("/data/autoInvoices") ||
                    asPath.includes("/data/adminAutoInvoices") ||
                    asPath.includes("/data/articles")
                  }
                >
                  <div className="innerMenu">
                    <Sidebar.Item href="/data">
                      <Item displayName="företagsuppgifter" path="data" />
                    </Sidebar.Item>
                    <Sidebar.Item href="/data/checklists">
                      <Item displayName="Mallar" path="checklists" />
                    </Sidebar.Item>
                    <Sidebar.Item href="/data/articles">
                      <Item displayName="Artiklar" path="articles" />
                    </Sidebar.Item>
                    <Sidebar.Item href="/data/autoInvoices">
                      <Item displayName="Fakturor" path="autoInvoices" />
                    </Sidebar.Item>
                    {(currentUser?.email === "adminap2@grr.la" ||
                      currentUser?.email ===
                        "mattias.jansson@ljungheden.com") && (
                      <Sidebar.Item href="/data/adminAutoInvoices?pricingType=project">
                        <Item
                          displayName="Admin fakturor"
                          path="adminAutoInvoices"
                        />
                      </Sidebar.Item>
                    )}
                  </div>
                </Sidebar.Collapse>
              </div>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </div>
      </Sidebar>
    </div>
  );
}
