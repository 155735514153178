import { collection, doc } from "firebase/firestore";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { db, useCurrentAuth } from "./FirebaseProvider";

export const useGetPublicUserTenants = () => {
    const [profile] = useCurrentAuth();
    const [userValue] = useDocumentData(
        doc(db, `public_users/${profile?.email || "no-id"}`)
      );
      const [publicTenants] = useCollectionData(
        collection(db, "public_tenants")
      );
    
      const tenants = publicTenants?.filter((x) =>
        userValue?.tenants?.find((c: any) => c === x?.id)
      ) as any[];

      return tenants
  };