// @ts-nocheck
import { Fragment, InputHTMLAttributes } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { useFormContext } from "react-hook-form";
import clsx from "clsx";
import { SelectItem } from "../../../../common";
import _ from "lodash";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface IProps extends InputHTMLAttributes<HTMLButtonElement> {
  title: string;
  name: string;
  required?: boolean;
  items: string[] | SelectItem<any>[];
}

export const SelectOption: React.FC<IProps> = (props) => {
  const {
    formState,
    reset,
    register,
    getValues,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const error = formState.errors[props.name];
  const status = watch(props.name);
  const visibleValue = _.upperFirst(status);
  const foundItem = props.items.find((x) =>
    props.type === "number" ? +x.id === status : x.id === status
  );

  return (
    <Listbox
      {...register(props.name, { required: !!props.required })}
      value={typeof status === "number" ? status.toString() : status}
      disabled={props.disabled}
      onChange={(val) => {
        if (typeof val === "object") {
          setValue(props.name, props.type === "number" ? +val.id : val.id);
        } else {
          setValue(props.name, props.type === "number" ? +val : val);
        }
        reset(getValues(), { keepErrors: true });
        clearErrors();
      }}
    >
      {({ open }) => (
        <>
          <div className="relative col-span-3 mt-1">
            <Listbox.Label className="block pb-1 text-left text-sm font-medium text-gray-700">
              {props.title}
              {props?.required ? "*" : ""}
            </Listbox.Label>
            <Listbox.Button
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={clsx(
                "relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2.5 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm",
                {
                  "border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500":
                    !error,
                },
                {
                  "border-red-300 pr-10 text-red-300 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
                    error,
                },
                {
                  "disabled:cursor-not-allowed": props.disabled,
                },
                props.className
              )}
              {...props}
            >
              <span className="flex items-center">
                <span className="block truncate">
                  {props.items.every((x) => typeof x === "object") && foundItem
                    ? _.upperFirst(foundItem.name)
                    : _.upperFirst(props.items.find((x) => x === status?.id)) ||
                      visibleValue ||
                      `Välj ${props.title}`}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                {error ? (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ExclamationCircleIcon
                      className="h-6 w-6 fill-red-500 text-white"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.items.map((val, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={val}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {typeof val === "string"
                              ? _.upperFirst(val)
                              : _.upperFirst(val.name)}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
