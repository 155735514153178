import "../styles/global.css";
import "../styles/interface.css";
import "moment/locale/sv";
import "react-datepicker/dist/react-datepicker.css";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AppProps } from "next/app";
import { Toaster } from "react-hot-toast";
import AlertModal from "@/modals/AlertModal";
import { MainMenu } from "@/components/MainMenu/MainMenu";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import InformModal from "@/modals/InformModal";
import { useSessionStore } from "@/context/store";
import { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [mounted, setMounted] = useState(false);
  const router = useRouter();

  // Fixes Hydration error!
  useEffect(() => setMounted(true), []);

  useEffect(() => {
    const handleRouteChange = () => {
      useSessionStore.setState({ referenceDelete: [], storageDelete: [] });
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  registerLocale("sv", sv);

  if (!mounted) return null;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MainMenu>
          <Component {...pageProps} />
        </MainMenu>
        <Toaster position="top-right" reverseOrder={false} />
        <AlertModal></AlertModal>
        <InformModal></InformModal>
      </LocalizationProvider>
    </>
  );
};

export default MyApp;
