import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IPublicUser } from "../../../../../common";
import { NameOf } from "@/utils/NameOf";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { FirestoreDataConverter, doc, setDoc } from "firebase/firestore";
import { db, useCurrentAuth } from "@/utils/FirebaseProvider";
import { SelectOption } from "@/components/SelectOption/SelectOption";
import { professions } from "@/utils/professions";
import { technologyArea } from "@/utils/technologyArea";
import { Section } from "@/components/Section";
import { UploadFile } from "@/components/Form/UploadFile";
import { SaveButton } from "@/components/Form/SaveButton";
import { toast } from "react-hot-toast";

export const publicUserConverter: FirestoreDataConverter<IPublicUser> = {
  toFirestore: (data) => data,
  fromFirestore: (data) => data.data() as IPublicUser,
};

function Index() {
  const form = useForm<IPublicUser>();
  const [currentProfile] = useCurrentAuth();
  const [publicUser, load] = useDocumentDataOnce(
    doc(db, `/public_users/${currentProfile?.email}`).withConverter(
      publicUserConverter
    )
  );

  useEffect(() => {
    if (!load) {
      form.reset({ ...publicUser });
    }
  }, [form, load, publicUser]);

  if (publicUser?.email !== currentProfile?.email || !currentProfile) {
    return null;
  }

  return (
    <>
      <FormProvider {...form}>
        <Section title={"Profil"} subTitle="Grunduppgifter" defaultOpen={true}>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Namn
              </label>
              <input
                type="text"
                id="first-name"
                autoComplete="given-name"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...form.register(NameOf<IPublicUser>("name"), {
                  required: true,
                })}
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="text"
                disabled
                id="email"
                autoComplete="email"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...form.register(NameOf<IPublicUser>("email"), {
                  required: true,
                })}
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Företag
              </label>
              <input
                type="text"
                id="company"
                autoComplete="company"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...form.register(NameOf<IPublicUser>("company_name"), {
                  required: true,
                })}
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Tel
              </label>
              <input
                type="number"
                id="phone"
                autoComplete="phone"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...form.register(NameOf<IPublicUser>("phone"), {
                  required: true,
                })}
              />
            </div>
            <div className="col-span-3">
              <SelectOption
                items={professions}
                name={NameOf<IPublicUser>("profession")}
                title={"Yrke"}
                required
              />
            </div>
            <div className="col-span-3">
              <SelectOption
                items={technologyArea}
                name={NameOf<IPublicUser>("technologyArea")}
                title={"Teknikområde"}
                required
              />
            </div>
            <div className="col-span-6 mt-4">
              <UploadFile
                isArray={true}
                name="images"
                required={false}
                customPath={`public_users/${currentProfile.email}/Images`}
              />
            </div>
          </div>
          <SaveButton
            onClick={form.handleSubmit(async (val) => {
              await setDoc(
                doc(db, `public_users/${currentProfile.email}`),
                val,
                { merge: true }
              );
              toast.success("Ändringar sparad");
            })}
          />
        </Section>
      </FormProvider>
    </>
  );
}

export default Index;
