/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

export const Section: React.FunctionComponent<{
  title: string;
  subTitle?: string;
  footer?: React.ReactNode;
  defaultOpen?: boolean;
}> = ({ title, subTitle, children, footer, defaultOpen = true }) => {
  const [accordion, setAccordion] = useState(false);

  const ref = useRef(null);
  useEffect(() => {
    setAccordion(defaultOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (ref.current) {
      // @ts-ignore
      const position = ref.current.offsetTop;
      window.scrollTo({
        top: position - 40,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
      <div className=" md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3
            ref={ref}
            className="text-lg font-medium leading-6 text-gray-900 "
            id={`${title}`}
          >
            {title}
          </h3>
          {subTitle && (
            <p className="mt-1 text-sm    text-gray-500">{subTitle}</p>
          )}
        </div>
        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
          <>
            <div
              className={clsx(
                "flex cursor-pointer justify-end p-2 align-bottom hover:underline "
              )}
              onClick={() => {
                setAccordion(!accordion);
                setTimeout(() => {
                  handleClick();
                }, 200);
              }}
            >
              <p>{accordion ? <Upp /> : <Down />}</p>
            </div>
            {accordion ? children : <></>}
          </>
        </div>
      </div>
      {accordion && <div className="mt-2">{footer}</div>}
    </div>
  );
};

const Down = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.75 11.25L15 20L6.25 11.25"
      stroke="#555555"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Upp = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 18.75L15 10L23.75 18.75"
      stroke="#555555"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
