import { useSessionStore } from "@/context/store";
import { useData } from "@/utils/useData";
import React from "react";
import { SearchIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import { useRouter } from "next/router";

interface IProps {
  title?: string;
  setSearchKey: (val: string) => void;
  centered?: boolean;
  disabled?: boolean;
}

export const SearchInput: React.FC<IProps> = ({
  setSearchKey,
  centered,
  disabled,
  title,
}) => {
  const searchKey = useSessionStore((z) => z.searchKey);
  return (
    <div className={clsx("flex", { "justify-center": centered })}>
      <input
        value={searchKey}
        disabled={disabled}
        className="mt-2 w-1/2 rounded-lg border border-gray-300 bg-gray-50 p-2 px-4 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        placeholder={title || "Sök"}
        onChange={(e) => setSearchKey(e.target.value)}
      />
      <span className="relative right-8 top-5">
        <SearchIcon width={20} color={disabled ? "lightGray" : "gray"} />
      </span>
    </div>
  );
};

export const UseDataSearchInput: React.FunctionComponent<any> = (props) => {
  const { collection } = useData();
  const router = useRouter();

  return (
    <>
      <SearchInput
        disabled={!collection[0] || router.asPath.includes("notebook")}
        setSearchKey={(s) =>
          useSessionStore.setState({
            searchKey: s,
          })
        }
      />
      {props.children}
    </>
  );
};
